<template>
  <v-card>
    <v-card-title primary-title> </v-card-title>
    <v-card-text>
      <p>Dear John Smith,</p>

      <p>Below is your current Volume Incentive Rebate (VIR) statement based on your participation in the program!</p>

      <v-container>
        <v-row>
          <v-col cols="12">
            <v-card height="100%">
              <v-card-title class="justify-center">
                Current VIR Status
              </v-card-title>
              <v-card-text>
                <v-simple-table>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <th width="50%" class="text-right">
                          YTD Purchases:
                        </th>
                        <td width="50%">$396,511.65</td>
                      </tr>
                      <tr>
                        <th width="50%" class="text-right">
                          Current Volume Rebate %
                        </th>
                        <td width="50%">5%</td>
                      </tr>
                      <tr>
                        <th width="50%" class="text-right">
                          Current Volume Rebate Amount
                        </th>
                        <td width="50%">$19,825</td>
                      </tr>
                      <tr>
                        <th width="50%" class="text-right">
                          Next Level
                        </th>
                        <td width="50%">Purchase $13,456 to reach a %6 volume rebate!</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      selectedCount: 0,
      isBusy: false,
      currentPage: 1,
      filters: {},
      items: [
        {
          target: "Volume Target",
          target_1: "$50,000",
          target_2: "$125,000",
          target_3: "$200,000",
          target_4: "$300,000"
        },
        {
          target: "Rebate",
          target_1: "2% Rebate",
          target_2: "3% Rebate",
          target_3: "4% Rebate",
          target_4: "5% Rebate"
        }
      ],
      headers: [
        {
          value: "target",
          text: ""
        },
        {
          value: "target_1",
          text: "Target 1",
          class: "text-center blue lighten-4"
        },
        {
          value: "target_2",
          text: "Target 2",
          class: "text-center blue lighten-3"
        },
        {
          value: "target_3",
          text: "Target 3",
          class: "text-center blue lighten-2"
        },
        {
          value: "target_4",
          text: "Target 4",
          class: "text-center blue lighten-1"
        }
      ],
      page: {
        size: 10,
        totalElements: 0,
        totalPages: 0,
        sort: null
      }
    };
  },
  mounted() {
    this.$store.dispatch("setBreadcrumb", []);
  }
};
</script>
